$primary-blue: #005dac;
$primary-black: #000000;
$primary-white: #ffffff;
$secondary-purple: #29176b;
$secondary-blue: #003594;
$light-blue: #4cb4e7;
$yellow: #ffc300;
$success-green: #00b68d;
$error-red: #e10404;
$warning-orange: #f79420;
$unselected-gray: #6e6e6e;
$light-gray: #ededed;

$blue: #005BA7;

// NOTE: Filters work fine with black svg icons
$unselected-gray-filter: invert(46%) sepia(0%) saturate(1298%) hue-rotate(134deg) brightness(91%) contrast(84%);
$primary-white-filter: invert(100%) sepia(91%) saturate(36%) hue-rotate(337deg) brightness(109%) contrast(109%);
$light-blue-filter: invert(64%) sepia(29%) saturate(938%) hue-rotate(158deg) brightness(96%) contrast(88%);
$primary-blue-filter: invert(15%) sepia(57%) saturate(6428%) hue-rotate(196deg) brightness(93%) contrast(102%);
$secondary-purple-filter: invert(6%) sepia(98%) saturate(5468%) hue-rotate(257deg) brightness(79%) contrast(94%);
$success-green-filter: invert(43%) sepia(71%) saturate(4113%) hue-rotate(144deg) brightness(108%) contrast(101%);
$error-red-filter: invert(29%) sepia(98%) saturate(5344%) hue-rotate(352deg) brightness(83%) contrast(117%);

$small-screen: 960px;
$tablet: 768px;
$extra-small-screen: 480px;
$two-column-content-width: 1062px;
$large-screen: 1280px;
