@import '~@ng-select/ng-select/themes/default.theme.css';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import './variables.scss';
@import './fonts.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  font-family: 'Poppins', sans-serif;
  min-width: 320px;
}
a:hover {
  text-decoration: none !important;
}
.block {
  margin: 0 0 24px;
}
h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 62px;
  font-weight: 300;
  line-height: 64px;
}

h2 {
  font-size: 32px;
  line-height: 42px;
  font-weight: 400;
  margin: 0 0 8px;
}
.embed {
  .main-layout{
    max-width: none !important;
  }
  h2{
    font-size: 20px !important;
    line-height: 22px !important;
    margin: 0;
  }
}
.main-heading {
  display: block;
  padding: 0 0 16px;
  &.result-heading{
    p.no-date{
      display: none;
    }
    .no-date {
      p{
        display: none;
      }
      p.no-date{
        display: block;
      }
    }
  }
  .cs-inner-html-16,
  .cs-inner-html-18 {
    margin: 0 0 8px;
  }
  &.large {
    margin: 0;
    h2,
    .cs-inner-html-18 {
      margin: 0 0 32px;
    }
  }
}
.embed{
  .form-group label{
    font-size: 15px;
    line-height: 15px;
    color: $primary-black;
  }
  .custom-checkbox .label-container{
    padding-left: 34px;
  }
  .cs-inner-html-checkbox{
    font-size: 11px;
    line-height: 13px;
  }
}

h3 {
  font-size: 26px;
  font-weight: 400;
  line-height: 32px;
  margin: 0 0 24px;
}

h4 {
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
}

p strong {
  font-weight: 700;
}

// ContentStack styles
.cs-inner-html-18 {
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  a {
    font-weight: 500;
    color: $primary-blue;
    text-decoration-line: underline;
  }
}

.cs-inner-html-16 {
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  a {
    font-weight: 500;
    text-decoration-line: underline;
    color: $primary-blue;
  }
}

.cs-inner-html-22 {
  display: flex;
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  flex-direction: column;
  gap: 32px;
  span {
    font-size: 26px;
    font-weight: 400;
    line-height: 32px;
  }
  .center-or span {
    align-self: center;
  }
}

@media (max-width: $extra-small-screen) {
  .cs-inner-html-16 {
    p {
      font-size: 14px;
      line-height: 22px;
    }
  }
  .cs-inner-html-22 {
    gap: 24px;
    span {
      font-size: 24px;
    }
  }
}

.call-us {
  .cs-inner-html-16 p {
    margin: 0 0 32px;
  }
  .cs-inner-html-16 span {
    display: block;
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
    margin: 0 0 24px;
  }
  .center-or {
    span {
      text-align: center;
    }
  }
  &.call-center {
    h2 {
      margin: 0 0 24px;
    }
  }
}
.center {
  text-align: center;
}
.cs-inner-html-large {
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  p {
    margin: 0 0 32px;
    a {
      font-weight: 500;
      text-decoration-line: underline;
      color: $primary-blue;
    }
  }
}

.cs-inner-html-checkbox {
  color: #000;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  a {
    text-decoration-line: underline;
    color: $primary-blue;
  }
}

.steps-wrapper {
  margin: 0 0 16px;
}

.text-link {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 24px;
  a {
    color: $primary-blue;
  }
}

ol {
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  list-style-position: inside;
  padding: 0 0 0 10px;
}

ol.custom-ordered-list {
  counter-reset: item;
  list-style: none;
  padding: 0;
  margin: 0 0 56px;
  font-size: 18px;
  font-weight: 300;

  li {
    counter-increment: item;
    position: relative;
    margin-bottom: 24px;
    padding-left: 64px;

    &::before {
      content: counter(item);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      background-color: $yellow;
      color: $primary-black;
      font-weight: 400;
      border-radius: 4px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 32px;
      line-height: 42px;
    }
  }
}
.blog-posts-frame {
  display: flex;
  justify-content: center;
  align-items: center;
  &.wide {
    align-items: baseline;
  }
}

.info-list {
  > li {
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 16px;
  }
  .icon {
    min-width: 80px;
    max-width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .title {
    font-size: 26px;
    line-height: 32px;
    font-weight: 400;
    display: block;
    margin-bottom: 8px;
  }
}

.names-list {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  width: 100%;
  .name {
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 2px solid $primary-blue;
    background: $primary-white;
    color: $primary-blue;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-transform: capitalize;
    cursor: pointer;
    &.selected {
      color: $primary-white;
      background: $secondary-purple;
      border-color: $secondary-purple;
    }
  }
}

.accordion {
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  p {
    margin: 0 0 24px;
  }
  .item {
    position: relative;
    padding: 0 0 46px 64px;
    .expanded-block {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.5s ease-in-out;
    }
    &:last-child:before {
      display: none;
    }
    &:after {
      clear: both;
      content: '';
      position: absolute;
      left: 8px;
      top: 0;
      width: 36px;
      height: 36px;
      background: $yellow url('assets/icons/plus-icon.svg') no-repeat 50% 50%;
      border-radius: 50%;
      cursor: pointer;
    }
    &:before {
      clear: both;
      content: '';
      position: absolute;
      left: 22px;
      top: 3px;
      bottom: 0;
      width: 8px;
      background: $yellow;
    }
    &.active {
      .expanded-block {
        max-height: 1500px;
      }
      .heading {
        color: $primary-blue;
      }
      &:after {
        background: $primary-blue url('assets/icons/minus-icon.svg') no-repeat
          50% 50%;
      }
      &:before {
        background: $primary-blue;
      }
      &:last-child:before {
        display: block;
      }
    }
  }
  .heading {
    padding: 0 0 24px;
    cursor: pointer;
    .title {
      display: block;
      font-size: 32px;
      font-weight: 400;
      line-height: 42px;
      padding: 0 0 4px;
    }
    .subtitle {
      display: block;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 1.28px;
      text-transform: uppercase;
    }
  }
  .tip {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    border: 1px solid $unselected-gray;
    padding: 16px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    margin: 0 0 24px;
    p {
      margin: 0;
    }
    .title {
      display: block;
      font-size: 26px;
      font-weight: 400;
      line-height: 32px;
      margin: 0 0 8px;
    }
    .visual {
      margin: 0 24px 0 0;
    }
  }
  .visual-list {
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    margin: 0 0 24px;
    li {
      display: flex;
      align-items: center;
      margin: 0 0 8px;
      p {
        margin: 0;
      }
    }
    .visual {
      width: 48px;
      min-width: 48px;
      height: 48px;
      padding: 8px;
      border-radius: 8px;
      background: $primary-blue;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 16px 0 0;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
app-progress-bar {
  position: sticky;
  top: 96px;
  background: #fff;
  margin-top: -32px;
  margin-bottom: 16px;
  padding-top: 32px;
  z-index: 1000;
  &.hidden{
    margin: 0;
    padding: 0;
    height: 0;
    overflow: hidden;
  }
}

.card {
  .description {
    .cs-inner-html-16 {
      p {
        min-height: 78px;
      }
    }
  }
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-32 {
  margin-bottom: 32px;
}

.pb-34 {
  padding-bottom: 34px;
}

.d-block {
  display: block;
}

// SELECT STYLES
.ng-select.state-select.ng-select-focused:not(.ng-select-opened)
  > .ng-select-container {
  border-color: $primary-blue;
  border-width: 2px;
  box-shadow: none;
}

.ng-select.ng-select-opened.ng-select-top > .ng-select-container {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  color: $primary-black;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: $yellow;
  color: $primary-black;
}

.ng-dropdown-panel {
  border-radius: 8px;
  border: 1px solid $unselected-gray;
  background: $primary-white;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.ng-dropdown-panel.ng-select-top {
  bottom: 100%;
  border-bottom-color: $unselected-gray;
  margin-bottom: 2px;
  border: none;
}

.ng-dropdown-panel.ng-select-bottom {
  bottom: 100%;
  border-bottom-color: $unselected-gray;
  margin-top: 2px;
  border: none;
}

.ng-dropdown-panel.ng-select-top
  .ng-dropdown-panel-items
  .ng-option:first-child {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

// first child for top position
.ng-dropdown-panel.ng-select-top
  .ng-dropdown-panel-items
  .ng-option:first-child {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

// first child for top position
.ng-dropdown-panel.ng-select-bottom
  .ng-dropdown-panel-items
  .ng-option:first-child {
  border-radius: 8px;
}

.ng-dropdown-panel .scroll-host {
  border-radius: 8px;
  border: 1px solid $unselected-gray;
}

.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected
  .ng-option-label,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked
  .ng-option-label {
  font-weight: 300;
}

.ng-option-label {
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked {
  color: $primary-black;
  background-color: $light-gray;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 5px;
  left: 0;
  padding-left: 10px;
  padding-right: 32px;
}

.ng-select.state-select {
  position: relative;
  border-radius: 8px;
  background: $primary-white;
  color: $unselected-gray;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  cursor: pointer;
  text-align: left;
  height: 44px;
  width: 100%;
  &.ng-select-single {
    .ng-select-container {
      width: 100%;
      height: 100%;
    }
  }
  .ng-select-container {
    width: 100%;
    height: 100%;
    color: $unselected-gray;
    background-color: $primary-white;
    border-radius: 8px;
    border: 1px solid $unselected-gray;
    min-height: 44px;
    align-items: center;
    padding: 8px;
    .ng-value-container {
      padding-left: 0;
      width: 100%;
    }
    .ng-arrow-wrapper {
      .ng-arrow {
        mask-image: url('/assets/icons/chevron-down.svg');
        mask-size: contain;
        -webkit-mask-image: url('/assets/icons/chevron-down.svg');
        -webkit-mask-size: contain;
        background: center / contain no-repeat
          url('/assets/icons/chevron-down.svg');
        height: 24px;
        top: 5px;
        width: 24px;
        border: none;
        filter: $unselected-gray-filter;
      }
    }
  }

  &.ng-select-opened {
    .ng-select-container {
      .ng-arrow-wrapper {
        .ng-arrow {
          mask-image: url('/assets/icons/chevron-up.svg');
          mask-size: contain;
          -webkit-mask-image: url('/assets/icons/chevron-up.svg');
          -webkit-mask-size: contain;
          background: center / contain no-repeat
            url('/assets/icons/chevron-up.svg');
          height: 24px;
          top: 5px;
          width: 24px;
          border: none;
          filter: $unselected-gray-filter;
        }
      }
    }
  }

  .ng-dropdown-panel {
  }
}

// ngx-owl-carousel-o
.owl-carousel .owl-stage-outer {
  background: linear-gradient(
      0deg,
      rgba(76, 180, 231, 0.2) 0%,
      rgba(76, 180, 231, 0.2) 100%
    ),
    var(--colors-Primary-White, #fff);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.owl-dots {
  padding-top: 12px;
  background-color: $primary-white;
}

.owl-theme .owl-dots .owl-dot span {
  background: $light-gray !important;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: $primary-blue !important;
}

// SCROLLBAR >>>
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $unselected-gray;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  // background: #555;
}
// SCROLLBAR <<<

.remove-btn {
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  background-color: $primary-white;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s;
  cursor: pointer;
  z-index: 1;
  &:hover {
    opacity: 0.6;
  }
  img {
    transition: 0.3s;
    filter: $error-red-filter;
    width: 100%;
    height: 100%;
  }
  &.phone {
    top: 50px;
  }
}

.add-btn {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding: 4px 0;
  color: $primary-black;
  background-color: $primary-white;
  border-radius: 8px;
  border: none;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-transform: capitalize;
  transition: 0.3s;
  cursor: pointer;
  margin: 0 0 20px;
  img {
    transition: 0.3s;
  }
  & > img {
    line-height: 16px;
    width: 22px;
    height: 22px;
  }
  &:hover {
    opacity: 0.6;
  }
}

.owl-container {
  position: relative;
}

.comment-block {
  display: flex;
  gap: 8px;
  padding: 0 40px 24px;
  &.expanded{
    .text{
      display: block;
      -webkit-box-orient:unset;
      overflow: visible;
      text-overflow:unset;
      -webkit-line-clamp:unset;
    }
  }
}

.quote {
  height: 64px;
  width: 64px;
  min-width: 64px;
  color: $primary-blue;
  text-align: center;
  font-size: 96px;
  font-style: normal;
  font-weight: 400;
  line-height: 112%;
}

.comment {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 14px;
  .text {
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
  }
  .author {
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
  }
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.offer-bar{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: $yellow;
  border-radius: 8px 8px 0 0;
  z-index: 2000;
  &.booking{
    bottom: auto;
    top: 160px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: 2001 !important;
    &.fade-in {
      opacity: 1;
    }
    &.fade-out {
      opacity: 0;
    }
  }
  .frame{
    margin: 0 auto;
    max-width: 1280px;
    padding: 16px;
    display: flex;
    align-items: center;
    background: url('/assets/icons/offer-bg.svg') 70% 50% no-repeat ;
    background-size: contain;
  }
  .icon{
    margin: 0 24px 0 0;
    img{
      max-width: 100%;
      max-height: 100%;
      vertical-align: top;
    }
  }
  .offer-title{
    font-size: 26px;
    font-weight: 500;
    line-height: 32px;
    display: block;
    padding: 0 0 6px;
  }
  p{
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
  }
}

.ckeckmark-item {
  text-align: center;
  height: 82px;
  margin: 0 0 16px;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

@media (max-width: $small-screen) {
  .quote {
    width: 56px;
    height: 56px;
    min-width: 56px;
    font-size: 64px;
  }
  .comment {
    .text {
      font-size: 20px;
    }
    .author {
      font-size: 14px;
    }
  }
}

@media (max-width: 767px) {
  .comment {
    .text {
      -webkit-line-clamp: 5;
    }
  }
}

@media (max-width: $large-screen) {
  .blog-posts-frame {
    flex-direction: column;
    &.wide {
      align-items: center;
    }
  }
  app-progress-bar {
    top: 95px;
  }
}

@media (max-width: $small-screen) {
  h1 {
    font-size: 40px;
    line-height: 46px;
  }

  h2 {
    font-size: 24px;
    line-height: 32px;
  }

  h3 {
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 8px;
  }

  ol {
    font-size: 14px;
    line-height: 22px;
  }

  ol.custom-ordered-list {
    margin: 0 0 36px;
  }

  .block {
    margin: 0;
  }

  .cs-inner-html {
    p {
      font-size: 16px;
      a {
        font-size: 16px;
      }
    }
  }

  .cs-inner-html-16 {
    font-size: 14px;
    line-height: 22px;
    p {
      margin: 0;
    }
  }
  .cs-inner-html-large {
    font-size: 20px;
  }

  .call-us {
    .cs-inner-html-16 p {
      margin: 0 0 24px;
      font-size: 20px;
      line-height: 28px;
    }
    .cs-inner-html-16 span {
      font-size: 20px;
      line-height: 28px;
      margin: 0 0 24px;
    }
    &.call-center {
      h2 {
        margin: 0 0 24px;
      }
    }
  }

  .info-list {
    .title {
      font-size: 24px;
    }
  }

  .names-list {
    gap: 22px;
  }

  .card {
    .description {
      .cs-inner-html-16 {
        p {
          min-height: 0;
        }
      }
    }
  }

  .mb-24-m {
    margin-bottom: 24px;
  }

  .h5-m {
    font-size: 20px;
    line-height: 28px;
  }

  .cs-inner-html-18 {
    font-size: 16px;
    line-height: 26px;
  }
}
@media (max-width: $extra-small-screen) {
  .accordion {
    .item {
      padding: 0 0 46px 56px;
      &:after {
        width: 28px;
        height: 28px;
        left: 8px;
      }
      &:before {
        left: 20px;
        width: 4px;
      }
    }
    .heading {
      .title {
        font-size: 28px;
        line-height: 36px;
      }
    }
    .visual-list {
      font-size: 14px;
      line-height: 22px;
      .visual {
        width: 32px;
        min-width: 32px;
        height: 32px;
        padding: 6px;
      }
    }
  }
  .embed{
    app-form-group .form-group{
      margin: 0;
    }
   .main-heading.modify-text .cs-inner-html-18 p {
      font-size: 12px;
      line-height: 16px;
    }
  }
  .offer-bar{
    .frame{
      padding: 16px 24px;
    }
    .offer-title{
      font-size: 24px;
      line-height: 32px;
    }
    .icon{
      display: none;
    }
    p{
      font-size: 14px;
      line-height: 22px;
    }
  }
  .ckeckmark-item {
    height: 36px;
  }
}
@media (max-width: 767px) {
  app-progress-bar {
    top: 56px;
  }
  .offer-bar.booking{
    top: 56px;
  }
}
